import { formatDistance } from 'date-fns/formatDistance';

import type Reference from '@models/Reference';

export function camelize(text: string, firstLetter = false): string {
  const value = text.replace(/_([A-Za-z])/, (_, p1) => p1.toUpperCase());
  return firstLetter ? capitalize(value) : value;
}

export function capitalize(text: string): string {
  if (!text) return '';

  return text[0].toUpperCase() + text.slice(1);
}

export function compact(text: string): string {
  return text.replace(/\s+/g, '');
}

function durationToString(minutes: number, description: string): string {
  return minutes > 0 ? `${description}, ${minutes} ${pluralize('minute', minutes)}` : description;
}

export function displayDuration(minutes: number) {
  if (minutes < 60) {
    return `${minutes} ${minutes === 1 ? 'minute' : 'minutes'}`;
  } else if (minutes < 24 * 60) {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    const hoursText = `${hours} ${pluralize('hour', hours)}`;

    return durationToString(remainingMinutes, hoursText);
  } else {
    const days = Math.floor(minutes / (24 * 60));
    const remainingMinutes = minutes % (24 * 60);
    const hours = Math.floor(remainingMinutes / 60);
    const remainingMinutesInHour = remainingMinutes % 60;

    const durationText = `${days} ${pluralize('day', days)}, ${hours} ${pluralize('hour', hours)}`;

    return durationToString(remainingMinutesInHour, durationText);
  }
}

// Convert number to a given base and round it so decimal's length is within provided `decimal` argument.
// Zero only decimals, are stripped out from the returned value.
export function convert(value: number, base: number, decimals = 2) {
  const res = value / base;
  return ~~res === res ? ~~res : Number(res.toFixed(decimals));
}

export function idStringifier(s: Reference): { name: string; value: string } {
  return { name: s.name, value: s.id?.toString() || '' };
}

export function ordinalize(number: number): string {
  const suffixes = ['th', 'st', 'nd', 'rd'];
  const rem = number % 100;
  const suffix = suffixes[(rem - 20) % 10] || suffixes[rem] || suffixes[0];

  return `${number}${suffix}`;
}

const ORDINALS = [
  'first',
  'second',
  'third',
  'fourth',
  'fifth',
  'sixth',
  'seventh',
  'eighth',
  'ninth',
  'tenth',
  'eleventh',
  'twelfth',
  'thirteenth',
  'fourteenth',
  'fifteenth',
  'sixteenth',
  'seventeenth',
  'eighteenth',
  'nineteenth',
  'twentieth',
  'twenty-first',
  'twenty-second',
  'twenty-third',
  'twenty-fourth',
  'twenty-fifth',
  'twenty-sixth',
  'twenty-seventh',
  'twenty-eighth',
  'twenty-ninth',
  'thirtieth',
  'thirty-first',
];

export function ordinalToNumber(ordinal: string): number {
  return ORDINALS.indexOf(ordinal) + 1;
}

export function squish(text: string): string {
  return text.trim().replace(/\s+/g, ' ').replace(/\n|\r/g, '');
}

export function sentence(elements: string[]): string {
  switch (elements.length) {
    case 0:
      return '';
    case 1:
      return elements[0];
    case 2:
      return `${elements[0]} and ${elements[1]}`;
    default:
      return `${elements.slice(0, -1).join(', ')} and ${elements[elements.length - 1]}`;
  }
}

export function withArticle(name: string): string {
  return /^[aeiou]/i.test(name) ? `an ${name}` : `a ${name}`;
}

export function camelCaseToNormalText(text: string) {
  return text.replace(/([a-z])([A-Z])/g, '$1 $2').toLowerCase();
}

export function snakeCaseToNormalText(text: string) {
  return text.split('_').join(' ');
}

export function withNegation(count: number): string {
  return count > 1 ? "don't" : "doesn't";
}

export function pluralize(text: string, count: number) {
  const endWithEsPattern = /(?:s|sh|ch|x|z)$/;
  const vowelPattern = /[aeiou]/;

  if (count > 1) {
    if (endWithEsPattern.test(text)) {
      return text + 'es';
    } else if (text.endsWith('y') && !vowelPattern.test(text.charAt(text.length - 2))) {
      return text.slice(0, -1) + 'ies';
    } else {
      return text + 's';
    }
  }

  return text;
}

export function unixTimestampToDateString(timestamp: number) {
  return new Date(timestamp * 1000).toISOString().split('T')[0];
}

// This function format date in the following format: "DD/MMM/YYYY", example: "10 June, 2023".
export function formatDateLong(dateString: string | null) {
  if (!dateString) return '';

  const date = new Date(dateString);

  return `${date.toLocaleString('default', { month: 'long' })} ${date.getDate()}, ${date.getFullYear()}`;
}

export function distanceOfTimeFromNow(timeInFuture: string | null) {
  if (!timeInFuture) return '';

  const now = new Date();
  const future = new Date(timeInFuture);

  return formatDistance(future, now, { addSuffix: true });
}

export const extractSubdomain = (host: string) => {
  if (!host) return '';

  const subdomainRegex = /^(?:https?:\/\/)?([a-zA-Z0-9-]+)\./;

  const matches = host.match(subdomainRegex);

  if (matches && matches.length > 1) {
    return matches[1];
  }

  return '';
};
