import { createContext } from 'react';

import type User from '@models/User';

export const NULL_USER: User = {
  id: 0,
  externalId: '',
  name: '',
  email: '',
  role: '',
  slug: '',
  photo: null,
  jobTitle: null,
  schedulable: false,
  resourcePageEnabled: false,
  twoFactorEnabled: false,
};

export default createContext<User>(NULL_USER);
