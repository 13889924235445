import { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent';

if (process.env.NEW_RELIC_BROWSER_LICENSE_KEY !== '') {
  new BrowserAgent({
    init: {
      ajax: { enabled: true },
      jserrors: { enabled: true },
      page_view_timing: { enabled: true },
      session_replay: { enabled: false },
      session_trace: { enabled: true },
      spa: { enabled: false },
    },
    info: {
      beacon: 'bam.nr-data.net',
      errorBeacon: 'bam.nr-data.net',
      licenseKey: process.env.NEW_RELIC_BROWSER_LICENSE_KEY,
      applicationID: process.env.NEW_RELIC_BROWSER_APP_ID,
      sa: 1,
    },
    loaderConfig: {
      accountID: '4120192',
      trustKey: '4120192',
      agentID: process.env.NEW_RELIC_BROWSER_AGENT_ID,
      licenseKey: process.env.NEW_RELIC_BROWSER_LICENSE_KEY,
      applicationID: process.env.NEW_RELIC_BROWSER_APP_ID,
    },
  });
}
